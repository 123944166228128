import React, { useEffect } from 'react';

const TwitchChatViewer = () => {
    useEffect(() => {
        const embed = new window.Twitch.Embed('twitch-embed', {
            width: '100%',
            height: 500,
            channel: 'nolaqueenrell',
            // allowfullscreen: true,
            layout: 'video-with-chat',
        });

        embed.addEventListener(window.Twitch.Embed.VIDEO_READY, () => {
            const player = embed.getPlayer();
            player.play();
        });
    }, []);

    return (
        <div id="twitch-embed"></div>
    );
};

export default TwitchChatViewer;
