import React, { useState } from 'react';

const Form = () => {
    const initialFormData = {
        name: '',
        email: '',
        message: '',
    };

    const [formData, setFormData] = useState(initialFormData);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setFormData(initialFormData);
        // Set submitted state to true
        setIsSubmitted(true);

        try {
            const response = await fetch('/.netlify/functions/submitForm', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                // Form submitted successfully, handle success state or redirect
                console.log('Form submitted successfully');
            } else {
                // Handle form submission error
                console.error('Form submission error');
            }
        } catch (error) {
            // Handle fetch error
            console.error('Error during form submission:', error);
        }


    };

    return (
        <>
            <form onSubmit={handleSubmit} data-netlify="true" method='POST' name='contact' netlify className='form'>

                <div className='form-container'>
                    <h2>Send Us a Message</h2>
                    <p>Have any questions? Comments? Suggestions? Feel free to use the form to send a message!</p>
                    <div className="form-field" >
                        <label htmlFor="name">
                            <i className="far fa-user"></i> Name
                        </label>
                        <input
                            name="name"
                            type="text"
                            placeholder="e.g. john doe"
                            value={formData.name}
                            onChange={handleChange}
                            required />
                    </div>
                    <div className="form-field" >
                        <label htmlFor="email">
                            <i className="far fa-envelope"></i> Email
                        </label>
                        <input
                            name="email"
                            type="text"
                            placeholder="email@domain.com"
                            value={formData.email}
                            onChange={handleChange}
                            required />
                    </div>
                    <div className="form-field" >
                        <label htmlFor="message">
                            <i className="far fa-edit"></i> Message
                        </label>
                        <textarea
                            name="message"
                            placeholder="Type message here"
                            value={formData.message}
                            onChange={handleChange}
                            rows="10"
                            required>

                        </textarea>
                    </div>
                    <button type="submit" className='form-submit-button'>Send Message</button>
                </div>


            </form>
            {isSubmitted && (
                alert("Message sent successfully!")
            )}
        </>

    );
};

export default Form;



