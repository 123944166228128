import React from 'react';
import Socials from './components/Socials';
import Navbar from './components/Navbar';
import Clips from './components/Clips';
import Affiliate from './components/Affiliate';
import About from './components/About';
import Footer from './components/Footer';
import Twitch from './components/Twitch';
import Form from './components/Form';

// import TwitchAuthButton from './components/TwitchAuthButton';

function App() {
  return (
    <>
      <div className="title" id='top'>
        <Navbar />
        <br />
        <br />
        <br />
        <br />
        <br />

        <video autoPlay muted loop className="background-video">
          <source src={require("./images/controller-crown.mp4")} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div className='home-content'>
          {/* <div className="image-container">
            <img src={require("./images/nolatitle.webp")} alt="Bouncing crown" className="animated-image" />
          </div> */}
          <div class="stage">
            <div class="layer"></div>
            <div class="layer"></div>
            <div class="layer"></div>
            <div class="layer"></div>
            <div class="layer"></div>
            <div class="layer"></div>
            <div class="layer"></div>
            <div class="layer"></div>
            <div class="layer"></div>
            <div class="layer"></div>
            <div class="layer"></div>
            <div class="layer"></div>
            <div class="layer"></div>
            <div class="layer"></div>
            <div class="layer"></div>
            <div class="layer"></div>
            <div class="layer"></div>
            <div class="layer"></div>
            <div class="layer"></div>
            <div class="layer"></div>
          </div>


          {/* <script>window.setTimeout = null;</script> */}

          {/* <div className='home-phrase'>
            <h1>Where Great Gaming Content Comes to Life!</h1>
          </div> */}
          {/* <div className='home-image'>
            <img src={require("./images/nola-main-crown.webp")} alt="Bouncing crown" className='main-crown-image' width="324" height="324" />
          </div> */}
        </div>
      </div>

      <section>
        <Twitch />
        {/* <TwitchAuthButton /> */}
      </section>

      <section id='about-section'>
        <About />
      </section>

      <section id='socials-section'>
        <Socials />
      </section>

      <section className='home-banner'>
        <div className='banner-image'>
          <img src={require('./images/home-playstation-controller.webp')} alt='Playstation Controller' width="324" height="293" className='banner-playstation' />
        </div>
      </section>

      <section id='clips-section'>
        <Clips />
      </section>

      <section className='home-banner'>
        <div className='banner-image'>
          <img src={require('./images/home-monitor-image.webp')} alt='Computer Monitor' width="324" height="234" className='banner-monitor' />
        </div>
      </section>

      <section id='affiliate-section'>
        <Affiliate />
      </section>

      <section className='home-banner'>
        <div className='banner-image'>
          <img src={require('./images/home-pc-gaming-setup.webp')} alt='PC Gaming Setup' width="324" height="324" className='banner-pc' />
        </div>
      </section>

      <br />
      <br />
      <br />

      <section id='form-section'>
        <br />
        <br />
        <br />
        <Form />
      </section>

      <br />
      <br />
      <br />

      <Footer />
    </>
  );
}
export default App;
