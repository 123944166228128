import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './CustomSliderStyles.css';
import IndividualClip from './IndividualClip';

function Clips() {

    const [settings, setSettings] = useState({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        slidesToShow: 2,
    });
    const settingsRef = useRef(settings);

    //Function to change slider when screen size changes
    useEffect(() => {
        const handleResize = () => {
            const newSettings = { ...settingsRef.current };
            if (window.innerWidth <= 880) {
                newSettings.slidesToShow = 1;
            } else {
                newSettings.slidesToShow = 2;
            }
            setSettings(newSettings);
        };

        // Initial setup
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const clipLinks = [
        // "https://clips.twitch.tv/embed?clip=TriangularBloodyEndivePanicVis-IQerjD_DBDzKcxr3&parent=localhost",
        // "https://clips.twitch.tv/embed?clip=WittyPolishedDurianPeoplesChamp-sDr6PXCJaCYLBuPq&parent=localhost",
        // "https://clips.twitch.tv/embed?clip=OddOptimisticGarlicDeIlluminati-4YnpDkQRCadsDA3A&parent=localhost",
        // "https://clips.twitch.tv/embed?clip=ResoluteDeliciousChoughOSkomodo-qvSq-qBvSSkkJGLl&parent=localhost",
        // "https://clips.twitch.tv/embed?clip=CovertSilkyShingleGivePLZ-zNH65XHA775j4ny6&parent=localhost",
        // "https://clips.twitch.tv/embed?clip=LivelyBetterOilFrankerZ-cPm2Cv5N33f41xSB&parent=localhost",
        // "https://clips.twitch.tv/embed?clip=DreamySwissMonkeyPermaSmug-NrQyCr39ReSt4O7n&parent=localhost",
        // "https://clips.twitch.tv/embed?clip=UglyColorfulHornetDogFace-ltG8eNiO8ByKmewv&parent=localhost",
        // "https://clips.twitch.tv/embed?clip=KitschyPricklyJamCoolStoryBro-yKVZ2OdFFqeLdLeY&parent=localhost",
        // "https://clips.twitch.tv/embed?clip=LittlePerfectCrabsNinjaGrumpy-RqTDc9pV8ApAl8rp&parent=localhost"

        "https://clips.twitch.tv/embed?clip=TriangularBloodyEndivePanicVis-IQerjD_DBDzKcxr3&parent=www.nolaqueenrell.com",
        "https://clips.twitch.tv/embed?clip=WittyPolishedDurianPeoplesChamp-sDr6PXCJaCYLBuPq&parent=www.nolaqueenrell.com",
        "https://clips.twitch.tv/embed?clip=OddOptimisticGarlicDeIlluminati-4YnpDkQRCadsDA3A&parent=www.nolaqueenrell.com",
        "https://clips.twitch.tv/embed?clip=ResoluteDeliciousChoughOSkomodo-qvSq-qBvSSkkJGLl&parent=www.nolaqueenrell.com",
        "https://clips.twitch.tv/embed?clip=CovertSilkyShingleGivePLZ-zNH65XHA775j4ny6&parent=www.nolaqueenrell.com",
        "https://clips.twitch.tv/embed?clip=LivelyBetterOilFrankerZ-cPm2Cv5N33f41xSB&parent=www.nolaqueenrell.com",
        "https://clips.twitch.tv/embed?clip=DreamySwissMonkeyPermaSmug-NrQyCr39ReSt4O7n&parent=www.nolaqueenrell.com",
        "https://clips.twitch.tv/embed?clip=UglyColorfulHornetDogFace-ltG8eNiO8ByKmewv&parent=www.nolaqueenrell.com",
        "https://clips.twitch.tv/embed?clip=KitschyPricklyJamCoolStoryBro-yKVZ2OdFFqeLdLeY&parent=www.nolaqueenrell.com",
        "https://clips.twitch.tv/embed?clip=LittlePerfectCrabsNinjaGrumpy-RqTDc9pV8ApAl8rp&parent=www.nolaqueenrell.com"
    ];

    //Slide-in effect for title
    useEffect(() => {
        function handleScroll() {
            const element = document.querySelector('.clip-scroll');
            if (element) {
                const elementPosition = element.getBoundingClientRect().top;
                const windowHeight = window.innerHeight;

                if (elementPosition < windowHeight / 2) {
                    element.classList.add('slide-in');
                }
            }
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <h1 className="clips-title clip-scroll">Clips</h1>
            <div className='clip-carousel-container'>
                <div className='clip-slider'>
                    <Slider {...settings}>
                        {clipLinks.map((clipLink, index) => (
                            <IndividualClip
                                key={index}
                                src={clipLink}
                                title='NolaQueenRell Clip'
                                height="278"
                                width="500"
                            />
                        ))}
                    </Slider>
                </div>
                <br />
                <br />
                <div className='see-more-container'>
                    <h2>Want to see more Clips?</h2>
                    <a href='https://www.twitch.tv/nolaqueenrell/clips?featured=false&filter=clips&range=all' target='_blank' rel="noreferrer" className='see-more-clips'><button>See more clips</button></a>
                </div>
            </div>
        </>
    );
}
export default Clips;

