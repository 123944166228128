import React from 'react';
import { useInView } from 'react-intersection-observer';

const IndividualClip = ({ src, title, height, width }) => {
    const [ref, inView] = useInView({
        triggerOnce: true, // Loads only once when it enters the viewport
    });

    return (
        <div ref={ref}>
            {inView ? (
                <iframe
                    src={src}
                    title={title}
                    height={height}
                    width={width}
                    frameBorder="1"
                    allowFullScreen="true"
                    className='clip-iframe'
                />
            ) : (
                <div>Loading...</div>
            )}
        </div>
    );
};

export default IndividualClip;
