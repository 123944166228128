import React, { useEffect } from 'react'

function About() {

    //Slide-in effect for title
    useEffect(() => {
        function handleScroll() {
            const element = document.querySelector('.text-scroll');
            if (element) {
                const elementPosition = element.getBoundingClientRect().top;
                const windowHeight = window.innerHeight;

                if (elementPosition < windowHeight / 2) {
                    element.classList.add('slide-in');
                }
            }
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            <div className="about-info-container">
                <div className="about-image">
                    <img src={require('../images/nola-about-pic.webp')} alt='nolaqueenrell' width='304' height='540' />
                </div>
                <div className="about-content">
                    <h1 className='text-scroll'>About Me</h1>
                    <p>Welcome to my world! I'm Rell, an unstoppable force hailing from the vibrant heart of the New Orleans metropolitan area, nestled in the soulful embrace of Louisiana. At my core, I'm a dynamic entrepreneur with an insatiable passion for gaming and creating content that'll keep you on the edge of your seat.</p>
                    <p>My journey as a variety streamer began in 2021, and it's been an electrifying rollercoaster ever since. Not long after I embarked on this exhilarating adventure, I achieved the prestigious title of Twitch Affiliate, a testament to the incredible community we've built together.</p>
                    <p>When you tune in to my channel, you're in for a wild ride through the virtual realms of gaming. My playground spans a diverse spectrum of games, including the chaotic streets of GTA V, heart-pounding matches in Dead by Daylight, hilarious escapades in Rec Room, the whimsical fun of Fall Guys, and the gripping narratives of indie and story-based games. Each session with me promises new adventures, laughter, and unforgettable moments.</p>
                    <p>So, whether you're looking for entertainment, camaraderie, or just a dash of New Orleans' unique flavor, you've come to the right place. Join me in this epic quest of pixels and pixels, and let's create some magical memories together.</p>
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <hr />
            <div className='teams-organizations-container'>
                <h1 className='teams-title'>Teams/Organizations</h1>
                <div className='teams-list-container'>
                    <div className='team-info'>
                        <div className='team-image'>
                            <img src={require('../images/bigcreatxr-title.webp')} alt='BigCreatxr Team' width='320' height='160' />
                        </div>
                        <div className='team-info-text'>
                            <h2>BigCreatxr</h2>
                            <p>A content collective made up of nano influencers from across the world where individual voices harmonize to create a symphony of innovation.</p>
                        </div>
                        <div className='team-visit-link'>
                            <a href='https://bigcreatxr.com/join' target='_blank' rel="noreferrer" className='team-link'><button>See More</button></a>
                        </div>
                        <hr className='team-divider' />
                    </div>

                    <div className='team-info'>
                        <div className='team-image'>
                            <img src={require('../images/twitch_black-guild.webp')} alt='Twitch Black Guild' width='320' height='160' />
                        </div>
                        <div className='team-info-text'>
                            <h2>Twitch Black Guild</h2>
                            <p>Unity Guilds provide support and uplift of creators and creator-driven events and initiatives all year long in a space where Guild members can celebrate each other's joy and share their authentic voices and experiences.</p>
                        </div>
                        <div className='team-visit-link'>
                            <a href='https://m.twitch.tv/directory/collection/twitch-unityguild' target='_blank' rel="noreferrer" className='team-link'><button>See More</button></a>
                        </div>
                        <hr className='team-divider' />
                    </div>
                    <div className='team-info'>
                        <div className='team-image'>
                            <img src={require('../images/black-girl-gamer-code.webp')} alt='Black Girl Gamer Code' width='200' height='160' />
                        </div>
                        <div className='team-info-text'>
                            <h2>Black Girl Gamer Code</h2>
                            <p>A Leading Digital Community for Women and Femmes of Color in Gaming and Tech.</p>
                        </div>
                        <div className='team-visit-link'>
                            <a href='https://www.browngirlgamercode.com/' target='_blank' rel="noreferrer" className='team-link'><button>See More</button></a>
                        </div>
                    </div>
                </div>

                <br />
                <br />
                <hr />

                <h1 className='teams-title'>Ambassadorships</h1>
                <div className='teams-list-container'>
                    <div className='team-info'>
                        <div className='team-image'>
                            <img src={require('../images/nola-gamehers.webp')} alt='Gamehers' width='320' height='160' />
                        </div>
                        <div className='team-info-text'>
                            <h2>The gamehers Ambassador Network</h2>
                            <p>The first and largest media platform and social networking community for women in gaming.</p>
                        </div>
                        <div className='team-visit-link'>
                            <a href='https://thegamehers.com/' target='_blank' rel="noreferrer" className='team-link'><button>See More</button></a>
                        </div>
                        <hr className='team-divider' />
                    </div>

                    <div className='team-info'>
                        <div className='team-image'>
                            <img src={require('../images/nola-dream-fund.webp')} alt='1000 Dream Fund' width='220' height='160' />
                        </div>
                        <div className='team-info-text'>
                            <h2>1000 Dreams Fund</h2>
                            <p>To support dreams of talented young women in need by providing access to critical funding, resources and meaningful mentor relationships.</p>
                        </div>
                        <div className='team-visit-link'>
                            <a href='https://1000dreamsfund.org/' target='_blank' rel="noreferrer" className='team-link'><button>See More</button></a>
                        </div>
                        <hr className='team-divider' />
                    </div>

                    <div className='team-info'>
                        <div className='team-image'>
                            <img src={require('../images/nola-AloeBud.webp')} alt='Aloe Bud Ambassador' width='260' height='160' />
                        </div>
                        <div className='team-info-text'>
                            <h2>Aloe Bud Ambassador </h2>
                            <p>Aloe Bud supports the highly connected human through small daily victories.</p>
                        </div>
                        <div className='team-visit-link'>
                            <a href='https://aloebud.app/' target='_blank' rel="noreferrer" className='team-link'><button>See More</button></a>
                        </div>
                    </div>
                </div>

                <br />
                <br />
                <hr />

                <h1 className='teams-title'>Partnerships</h1>
                <div className='teams-list-container'>
                    <div className='team-info'>
                        <div className='team-image'>
                            <img src={require('../images/nola-hackwork.webp')} alt='Gamehers' width='520' height='160' />
                        </div>
                        <div className='team-info-text'>
                            <h2>HackWork</h2>
                            <p>HackWork ties the bridge between creators and skilled talent on YouTube & Twitch. Find vetted freelancers within the industry.</p>
                        </div>
                        <div className='team-visit-link'>
                            <a href='https://hackwork.net/' target='_blank' rel="noreferrer" className='team-link'><button>See More</button></a>
                        </div>
                        <hr className='team-divider' />
                    </div>


                </div>



            </div>

        </>
    );
}
export default About;