import React, { useEffect } from 'react';
import TwitchChatViewer from './TwitchChatViewer';

const Modal = ({ showModal, closeModal }) => {
    useEffect(() => {
        const handleCloseModal = (event) => {
            if (event.target.classList.contains('modal-overlay')) {
                closeModal();
            }
        };

        if (showModal) {
            document.addEventListener('mousedown', handleCloseModal);
        } else {
            document.removeEventListener('mousedown', handleCloseModal);
        }

        return () => {
            document.removeEventListener('mousedown', handleCloseModal);
        };
    }, [showModal, closeModal]);
    // Render the modal only if showModal is true
    if (!showModal) return null;

    return (
        <div className="modal-overlay">
            <div className="modal">
                <div className="modal-content">
                    <span className="close" onClick={closeModal}>&times;</span>
                    <div className='modal-title'><img src={require("../images/nola-title-alternate.webp")} className='modal-title-image' alt='Nolaqueenrell Title' width="268" height="30" /></div>
                    <div className='modal-button'><a href="https://www.twitch.tv/nolaqueenrell"><button>Click here to watch from Twitch</button></a></div>
                    <TwitchChatViewer />
                </div>
            </div>
        </div>
    );
};

export default Modal;