import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from './Modal';

function Twitch() {
    const [isLive, setIsLive] = useState(false);

    useEffect(() => {
        const fetchStreamStatus = async () => {
            try {
                const response = await axios.get('https://api.twitch.tv/helix/streams?user_login=nolaqueenrell', {
                    headers: {
                        'Client-ID': `${process.env.REACT_APP_CLIENT_ID}`,
                        'Authorization': `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`
                    }
                });

                setIsLive(response.data.data.length > 0);
            } catch (error) {
                console.error('Error fetching stream status:', error);
            }
        };

        fetchStreamStatus();
        // Set interval to check stream status periodically
        const interval = setInterval(fetchStreamStatus, 60000);

        return () => clearInterval(interval);
    }, []);

    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <div>
            {isLive ? <div className='twitch-live-container'><button className='twitch-live-button' onClick={openModal}><div className='twitch-live'><img src={require('../images/nola-twitch-profile-pic.webp')} alt='Twitch Profile' className='twitch-profile-pic-live' /><p>Nolaqueenrell is live!</p><img src={require('../images/twitch-logo.webp')} alt='Twitch Logo' className='twitch-live-logo' /></div></button></div> : <div className='twitch-live-container'><button className='twitch-live-button' onClick={openModal}><div className='twitch-live'><img src={require('../images/nola-twitch-profile-pic.webp')} alt='Twitch Profile' className='twitch-profile-pic-offline' /><p>Nolaqueenrell is offline!</p><img src={require('../images/twitch-logo.webp')} alt='Twitch Logo' className='twitch-live-logo' /></div></button></div>}
            {/* Embed Twitch chat using iframe or react-twitch-embed */}
            {/* <button onClick={openModal}>Open Modal</button> */}
            <Modal showModal={showModal} closeModal={closeModal} />
        </div>
    );
}

export default Twitch;
