import React, { useEffect } from 'react'


function Socials() {

    useEffect(() => {
        function handleScroll() {
            const element = document.querySelector('.social-scroll');
            if (element) {
                const elementPosition = element.getBoundingClientRect().top;
                const windowHeight = window.innerHeight;

                if (elementPosition < windowHeight / 2) {
                    element.classList.add('slide-in');
                }
            }
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <h1 className="socials-title social-scroll">My Socials</h1>
            <div className='social-links'>
                <div className='social-link-container'>
                    <a href="https://www.twitch.tv/nolaqueenrell" target="_blank" rel="noreferrer">
                        <img src={require('../images/twitch-logo.webp')} alt="Twitch Logo" width="78" height="78" />
                        <h2>Twitch</h2>
                    </a>

                </div>
                <div className='social-link-container'>
                    <a href="https://www.youtube.com/channel/UCnDciJJsDjXb8vQK8Fe9dtw" target="_blank" rel="noreferrer">
                        <img src={require('../images/youtube-logo.webp')} alt="Youtube Logo" width="78" height="78" />
                        <h2>Youtube</h2>
                    </a>
                </div>
                <div className='social-link-container'>
                    <a href="https://vm.tiktok.com/ZTdaMaqNA/" target="_blank" rel="noreferrer">
                        <img src={require('../images/tiktok-logo.webp')} alt="Tiktok Logo" width="78" height="78" />
                        <h2>Tiktok</h2>
                    </a>
                </div>
                <div className='social-link-container'>
                    <a href="https://kick.com/nolaqueenrell" target="_blank" rel="noreferrer">
                        <img src={require('../images/kick-logo.webp')} alt="Kick Logo" width="78" height="78" />
                        <h2>Kick</h2>
                    </a>
                </div>
                <div className='social-link-container'>
                    <a href="https://trovo.live/s/nolaqueenrell?roomType=1" target="_blank" rel="noreferrer">
                        <img src={require('../images/trovo-logo.webp')} alt="Trovo Logo" width="78" height="78" />
                        <h2>Trovo</h2>
                    </a>
                </div>
                <div className='social-link-container'>
                    <a href="https://www.instagram.com/nolaqueenrell/" target="_blank" rel="noreferrer">
                        <img src={require('../images/instagram-logo.webp')} alt="Instagram Logo" width="78" height="78" />
                        <h2>Instagram</h2>
                    </a>
                </div>
                <div className='social-link-container'>
                    <a href="https://twitter.com/nolaqueenrell" target="_blank" rel="noreferrer">
                        <img src={require('../images/twitter-logo.webp')} alt="Twitter Logo" width="78" height="78" />
                        <h2>Twitter</h2>
                    </a>
                </div>
                <div className='social-link-container'>
                    <a href="https://discord.com/invite/M7D7kdm" target="_blank" rel="noreferrer">
                        <img src={require('../images/discord-logo.webp')} alt="Discord Logo" width="78" height="78" />
                        <h2>Discord</h2>
                    </a>
                </div>
                <div className='social-link-container'>
                    <a href="https://ko-fi.com/nolaqueenrell" target="_blank" rel="noreferrer">
                        <img src={require('../images/nola-kofi-logo.webp')} alt="Ko-Fi Logo" width="78" height="78" />
                        <h2>Ko-Fi</h2>
                    </a>
                </div>

            </div>
        </>
    );
}
export default Socials;