import React, { useEffect } from 'react'


function Affiliate() {

    //Slide-in effect for title
    useEffect(() => {
        function handleScroll() {
            const element = document.querySelector('.affiliate-scroll');
            if (element) {
                const elementPosition = element.getBoundingClientRect().top;
                const windowHeight = window.innerHeight;

                if (elementPosition < windowHeight / 2) {
                    element.classList.add('slide-in');
                }
            }
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <h1 className='affiliate-title affiliate-scroll'>Affiliations/Partnerships</h1>
            <div className="affiliate-links">
                <div className="affiliate-item">
                    <img src={require('../images/glytch-partner.webp')} alt="Glytch Energy Partner" width="648" height="648" id='glytch-partner' />
                    <p>WE ARE PARTNERED WITH GLYTCH ENERGY!!!!! You can use code NOLA on any purchase and receive 20% off. 🦊⚡️</p>
                    <a href="https://glytchenergy.com/?rfsn=7726324.b55fa0" target="_blank" rel="noreferrer"><button>Visit Site</button></a>
                </div>
                <div className="affiliate-item">
                    <img src={require('../images/ubisoft-creators.webp')} alt="Ubisoft Creators Program Logo" width="648" height="447" id='ubisoft-partner' />
                    <p>Prepare for an epic journey as we embark on an exciting partnership with Ubisoft Creators Program, where every stream becomes a portal to adventure and gaming magic!</p>
                </div>
                <div className="affiliate-item">
                    <img src={require('../images/nola-spacepanda-logo.webp')} alt="Space panda Logo" width="140" height="121" id='panda-partner' />
                    <p>We are partnered with SpacePandaDelta!!!! Blast off and save 20% by using code NOLA at check out. 🐼🪐</p>
                    <a href="https://spacepandadelta.com/discount/NOLA?ref=NOLA" target="_blank" rel="noreferrer"><button>Visit Site</button></a>
                </div>
                <div className="affiliate-item">
                    <img src={require('../images/nola-streamline-logo.webp')} alt="Streamline Threads Logo" width="648" height="324" id='streamline-affiliate' />
                    <p>We are Streamline Threads Affiliate! Checkout the link to support, and use discount code "NOLA" at checkout to get 15% off all items. Purchase anything from tea, coffee, merch, and accessories!</p>
                    <a href="https://streamlined.gg/nolaqueenrell" target="_blank" rel="noreferrer"><button>Visit Site</button></a>
                </div>
            </div>
        </>
    );
}
export default Affiliate;